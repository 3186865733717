import dayjs from "dayjs";
import "dayjs/locale/tr";
import isToday from "dayjs/plugin/isToday";
import relativeTime from "dayjs/plugin/relativeTime";

export const dateFormat = "DD MMMM YYYY";
export const shortDateFormat = "DD.MM.YYYY";
export const datetimeFormat = "DD MMMM YYYY, HH:mm";
export const timeFormat = "HH:mm";
export const numericDateFormat = "DD_MM_YYYY";

dayjs.extend(isToday);
dayjs.extend(relativeTime);
dayjs.locale("tr");

export { dayjs };
